<template>
  <div>

    <section>
      <div v-if="bannersLoading" class="">
        <div class="w-full animate-pulse min-h-8/10 h-full bg-grey-200">
            <div class="container">
              <div class="md:absolute inset-y-0 flex items-center">
                <div class="max-w-lg xl:max-w-xl">
                  <div class="w-10/12 h-8 bg-surface-grey"></div>

                  <div class="bg-surface-grey h-32 w-96 mt-6"></div>  

                  <div class="mt-3 flex">
                    <div class="bg-grey-900  p-3 rounded">
                      <div class="w-32 h-6 bg-surface-grey"></div>
                    </div>

                    <div class="border border-primary-black  p-3 rounded  ml-6">
                      <div class="w-32 h-6 bg-surface-grey flex items-center justify-start p-1">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8.5" r="8" fill="black" />
                          <path d="M11 8.5L6 11.5L6 5.5L11 8.5Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div v-else
        class="relative min-w-full min-h-8/10 bg-cover bg-no-repeat"
        :style="{ backgroundImage: `url(${banner.image})` }"
        v-for="banner in banners"
        :key="banner.bannersId"
      >
        <div class="container">
          <div class="md:absolute inset-y-0 flex items-center">
            <div class="max-w-lg xl:max-w-xl">
              <div
                class="text-4xl md:text-5xl xl:text-6xl text-grey-900 font-bold md:leading-20"
              >
                {{ banner.title }}
              </div>

              <div
                class="mt-6 text-grey-900 text-xs leading-6 md:text-base xl:text-lg font-normal"
              >
                {{ banner.bannerContent }}
              </div>

              <div class="mt-3 flex">
                <button
                  class="bg-grey-900 text-xs md:text-sm font-normal tracking-widest p-3 text-surface-white rounded focus:outline-none hover:shadow-xl transform duration-700"
                >
                  DISCOVER NOW
                </button>

                <a :href="banner.link" target="_blank"
                  class="text-grey-900 text-xs md:text-sm font-medium tracking-wider p-3 border border-primary-black rounded flex items-center justify-center ml-6 focus:outline-none hover:bg-primary-black hover:text-surface-white transform duration-700"
                >
                  <span class="mr-2">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8.5" r="8" fill="black" />
                      <path d="M11 8.5L6 11.5L6 5.5L11 8.5Z" fill="white" />
                    </svg>
                  </span>
                  WATCH THE FILM
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <main class="mt-16 md:mt-6 xl:mt-16 w-full">
      <section id="Featured-artworks" class="container overflow-hidden">
        <div class="">
          <div
            class="text-xs font-normal text-grey-700 tracking-widest uppercase"
          >
            POPULAR
          </div>
          <div class="flex items-center justify-between mt-2">
            <div class="flex items-center md:w-5/12 lg:w-4/12 xl:w-3/12">
              <div
                class="text-xl xl:text-2xl font-bold text-grey-900 tracking-wide"
              >
                Featured Artworks
              </div>
              <div class="-mb-2 hidden md:block">
                <button class="mx-3 pointer-events-none">
                  <svg
                    width="12"
                    height="23"
                    viewBox="0 0 12 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 22L2 11.5L11 1"
                      stroke="#CFCFCF"
                      stroke-width="2"
                    />
                  </svg>
                </button>
                <button class="mx-3 pointer-events-none">
                  <svg
                    width="13"
                    height="23"
                    viewBox="0 0 13 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L11 11.5L1 22"
                      stroke="black"
                      stroke-width="2"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="md:w-7/12 lg:w-8/12 xl:w-9/12 flex items-center">
              <hr class="border-grey-100 w-full hidden md:block" />
              <router-link
                :to="{ name: 'artworks' }"
                class="text-sm text-grey-400 font-normal w-16 flex justify-end hover:text-grey-700 transform duration-300 hover:underline"
              >
                SEE ALL
              </router-link>
            </div>
          </div>
        </div>
        <div class="mt-12">
          <div v-if="artworksLoading" class="">
              <div class="pb-12 px-2">
                <ul class="flex space-x-8 animate-pulse">
                  <li class="">
                    <div class="group">
                      <div class="border border-grey-100 p-8 md:p-10 lg:p-20 flex justify-center items-center w-full h-full">
                        <div class="bg-black-2 w-48 h-48 border border-grey-100">
                          <div class="bg-surface-grey w-full h-full"></div>
                        </div>
                      </div>
                      <div class="mt-6 bg-surface-grey w-64 h-6 mx-auto"></div>
                    </div>
                    <div class="mt-4 bg-surface-grey w-48 h-6 mx-auto"></div>
                  </li>

                  <li class="">
                    <div class="group">
                      <div class="border border-grey-100 p-8 md:p-10 lg:p-20 flex justify-center items-center w-full h-full">
                        <div class="bg-black-2 w-48 h-48 border border-grey-100">
                          <div class="bg-surface-grey w-full h-full"></div>
                        </div>
                      </div>
                      <div class="mt-6 bg-surface-grey w-64 h-6 mx-auto"></div>
                    </div>
                    <div class="mt-4 bg-surface-grey w-48 h-6 mx-auto"></div>
                  </li>

                  <li class="">
                    <div class="group">
                      <div class="border border-grey-100 p-8 md:p-10 lg:p-20 flex justify-center items-center w-full h-full">
                        <div class="bg-black-2 w-48 h-48 border border-grey-100">
                          <div class="bg-surface-grey w-full h-full"></div>
                        </div>
                      </div>
                      <div class="mt-6 bg-surface-grey w-64 h-6 mx-auto"></div>
                    </div>
                    <div class="mt-4 bg-surface-grey w-48 h-6 mx-auto"></div>
                  </li>
                </ul>
              </div>
          </div>
          <!-- v-if="artworks.length > 0" -->
          <div v-else  class="glide" id="glide1" dir="ltr">
            <div class="glide__track pb-12 px-2" data-glide-el="track">
              <ul class="glide__slides flex space-x-8" dir="rtl">
                <li :key="index" v-for="(artwork, index) in artworks" class="">
                  <router-link
                    :to="{ name: 'artwork', params: { id: artwork.artworkId } }"
                    class="group"
                  >
                    <div
                      class="border border-grey-100 p-8 md:p-10 lg:p-20 group-hover:border-grey-300 transform duration-700 flex justify-center items-center"
                    >
                      <img
                        class="bg-black-2 w-48 h-48"
                        :src="artwork.artWorkImage"
                        alt=""
                      />
                    </div>
                    <div
                      class="mt-6 text-xl xl:text-2xl text-grey-900 font-bold block text-center"
                    >
                      {{ artwork.artworkName }}
                    </div>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'producer',
                      params: { id: artwork.producerId },
                    }"
                    class="mt-4 text-sm text-grey-600 font-semibold block text-center hover:underline"
                  >
                    {{ artwork.producerName }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="Catagories" class="container mt-32 lg:mt-20 xl:mt-36">
        <div class="">
          <div
            class="text-xs font-normal text-grey-700 tracking-widest uppercase"
          >
            Discover
          </div>
          <div class="flex items-center justify-between mt-2">
            <div class="flex items-center md:w-2/12 lg:w-1/12">
              <div
                class="text-xl xl:text-2xl font-bold text-grey-900 tracking-wide"
              >
                Catagories
              </div>
            </div>

            <div class="md:w-10/12 lg:w-11/12 flex items-center pl-6">
              <hr class="border-grey-100 w-full hidden md:block" />
              <router-link
                :to="{ name: 'products' }"
                class="text-sm text-grey-400 font-normal w-16 flex justify-end hover:text-grey-700 transform duration-300 hover:underline"
              >
                SEE ALL
              </router-link>
            </div>
          </div>
        </div>

        <div class="">
          <div v-if="catagoriesLoading" class="animate-pulse grid grid-cols-2 gap-y-8 sm:flex items-center justify-center sm:space-x-8 md:space-x-14 mt-12">
            <div class="group flex flex-col justify-center items-center" >
              <div class="border border-grey-200 rounded-full w-20 md:w-24 h-20 md:h-24 flex justify-center items-center">
                <div class="w-14 md:w-16 h-14 md:h-16 p-2 bg-surface-grey rounded-full"></div>
              </div>
              <div class="mt-3 bg-surface-grey w-16 h-4"></div>
            </div>

            <div class="group flex flex-col justify-center items-center" >
              <div class="border border-grey-200 rounded-full w-20 md:w-24 h-20 md:h-24 flex justify-center items-center">
                <div class="w-14 md:w-16 h-14 md:h-16 p-2 bg-surface-grey rounded-full"></div>
              </div>
              <div class="mt-3 bg-surface-grey w-16 h-4"></div>
            </div>

            <div class="group flex flex-col justify-center items-center" >
              <div class="border border-grey-200 rounded-full w-20 md:w-24 h-20 md:h-24 flex justify-center items-center">
                <div class="w-14 md:w-16 h-14 md:h-16 p-2 bg-surface-grey rounded-full"></div>
              </div>
              <div class="mt-3 bg-surface-grey w-16 h-4"></div>
            </div>
          </div>

          <div v-else class="grid grid-cols-2 gap-y-8 sm:flex items-center justify-center sm:space-x-8 md:space-x-14 mt-12">
            <router-link
              :to="{
                name: 'products',
                query: { catagory_id: catagory.categoryId },
              }"
              class="group flex flex-col justify-center items-center"
              :key="index"
              v-for="(catagory, index) in catagories"
            >
              <div
                class="border border-grey-200 group-hover:border-grey-600 transform duration-500 rounded-full w-20 md:w-24 h-20 md:h-24 flex justify-center items-center"
              >
                <img
                  :src="catagory.image"
                  alt=""
                  class="w-14 md:w-16 h-14 md:h-16 transform duration-1000 hover:scale-110 p-2"
                />
              </div>
              <div class="mt-3 text-sm font-semibold text-grey-900 text-center">
                {{ catagory.categoryName }}
              </div>
            </router-link>
          </div>
        </div>
      </section>

      <section id="Match_your_needs" class="container mt-20">
        <div class="text-center">
          <div
            class="text-xs font-normal text-grey-700 tracking-widest uppercase"
          >
            Apparels
          </div>
          <div class="text-xl xl:text-2xl font-bold text-grey-900">
            Match your needs
          </div>
        </div>

        <div class="">
          <div v-if="subCatagoriesLoading" class="animate-pulse mt-8 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-x-3 lg:gap-x-4">
            <div class="relative group overflow-hidden rounded-lg h-64 md:h-72 lg:h-96 xl:h-100">
              
              <div class="bg-surface-grey w-full h-full rounded-lg"></div>

              <div class="absolute inset-0 rounded-lg flex items-end justify-center pb-6 bg-linear-gradient-catagory">
                <div class="bg-surface-grey w-32 h-6"></div>
              </div>
            </div>

            <div class="relative group overflow-hidden rounded-lg h-64 md:h-72 lg:h-96 xl:h-100">
              
              <div class="bg-surface-grey w-full h-full rounded-lg"></div>

              <div class="absolute inset-0 rounded-lg flex items-end justify-center pb-6 bg-linear-gradient-catagory">
                <div class="bg-surface-grey w-32 h-6"></div>
              </div>
            </div>

            <div class="relative group overflow-hidden rounded-lg h-64 md:h-72 lg:h-96 xl:h-100">
              
              <div class="bg-surface-grey w-full h-full rounded-lg"></div>

              <div class="absolute inset-0 rounded-lg flex items-end justify-center pb-6 bg-linear-gradient-catagory">
                <div class="bg-surface-grey w-32 h-6"></div>
              </div>
            </div>

            <div class="relative group overflow-hidden rounded-lg h-64 md:h-72 lg:h-96 xl:h-100">
              
              <div class="bg-surface-grey w-full h-full rounded-lg"></div>

              <div class="absolute inset-0 rounded-lg flex items-end justify-center pb-6 bg-linear-gradient-catagory">
                <div class="bg-surface-grey w-32 h-6"></div>
              </div>
            </div>
          </div>
        
          <div v-else class="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-x-3 lg:gap-x-4">
            <router-link
              :to="{
                name: 'products',
                query: { catagory_id: subCatagory.categoryId },
              }"
              class="relative group overflow-hidden rounded-lg h-64 md:h-72 lg:h-96 xl:h-100"
              :key="index"
              v-for="(subCatagory, index) in subCatagories"
            >
              <img
                :src="subCatagory.image"
                alt=""
                class="w-full h-full object-cover rounded-lg transform duration-1000 group-hover:scale-105"
              />
              <div
                class="absolute inset-0 rounded-lg flex items-end justify-center pb-6 text-2xl text-surface-white font-medium bg-linear-gradient-catagory"
              >
                <span class="hover:underline">{{
                  subCatagory.categoryName
                }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </section>

      <section id="Latest_work" class="container mt-32 xl:mt-44">
        <div class="text-center">
          <div
            class="text-xs font-normal text-grey-700 tracking-widest uppercase"
          >
            Recent
          </div>
          <div class="text-2xl font-bold text-grey-900">Latest Work</div>
        </div>

        <div class="">
          <div v-if="productsLoading" class="animate-pulse mt-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-16">
            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>

            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>

            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>
          </div>
        
          <div v-else class="mt-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-16">
            <router-link
              :to="{ name: 'product', params: { id: product.productId } }"
              :key="index"
              v-for="(product, index) in products"
              class="group"
            >
              <div
                class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96 hover:border-grey-300 transform duration-700"
              >
                <img :src="product.image" alt="" class="w-full h-full" />
              </div>
              <span
                class="mt-8 text-sm text-grey-500 font-normal block text-center"
              >
                {{ product.productName }}
              </span>
              <span class="mt-2 block text-center">
                <span class="text-lg text-grey-900 font-bold inline-block">{{
                  product.producerName
                }}</span>
                <!-- <span class="text-sm text-grey-900 font-normal mt-1 block">{{product.productPrice}}</span> -->
              </span>
            </router-link>
          </div>
        </div>

        <div v-if="!productsLoading" class="flex justify-center items-center mt-20">
          <router-link
            :to="{ name: 'products' }"
            class="text-base text-grey-900 font-normal px-8 lg:px-12 py-2 lg:py-3 border border-primary-black rounded hover:text-surface-white hover:bg-primary-black transform duration-700"
          >
            ALL PRODUCTS
          </router-link>
        </div>
      </section>
    </main>
  </div>
</template>



<script>
import Glide from "@glidejs/glide";

export default {
  mounted() {
    window.scroll(0, 0);
    this.getBanners();
    this.getCatagories();
    this.getSubCatagories();
    this.getProducts();
    this.getArtwroks();
   console.log('user',this.$authenticatedUser)

  },

  components: {},

  created() {},

  data() {
    return {
      pageNo: 1,
      pageSize: 4,
      banners: [],
      catagories: [],
      subCatagories: [],
      products: [],
      artworks: [],

      bannersLoading: true,
      artworksLoading: true,
      catagoriesLoading: true,
      subCatagoriesLoading: true,
      productsLoading: true,
    };
  },
  methods: {
    getBanners() {
      this.$http.homeService
        .GetBanners(this.pageNo, this.pageSize)
        .then((res) => {
          setTimeout(() => {
            this.data = res.data;
            this.banners = res.data.result.bannersInfo;

            this.bannersLoading = false;
          }, 500);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCatagories() {
      this.$http.homeService
        .GetCategories(this.pageNo, this.pageSize)
        .then((res) => {
          setTimeout(() => {
            this.data = res.data;
            this.catagories = res.data.result.categoriesInfo;

            this.catagoriesLoading = false;
          }, 500);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSubCatagories() {
      this.$http.homeService
        .GetSubCategories(this.pageNo, this.pageSize)
        .then((res) => {
          setTimeout(() => {
            this.data = res.data;
            this.subCatagories = res.data.result.subCategoriesInfo;

            this.subCatagoriesLoading = false;
          }, 500);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProducts() {
      this.$http.homeService
        .GetProducts(this.pageNo, this.pageSize)
        .then((res) => {
          setTimeout(() => {
            this.data = res.data;
            this.products = res.data.result.productsInfo;

            this.productsLoading = false;
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getArtwroks() {
      this.$http.homeService
        .GetArtwroks(this.pageNo, this.pageSize)
        .then((res) => {

          setTimeout(() => {
            this.data = res.data;
            this.artworks = res.data.result.artworks;

            if (this.artworks.length) {
              setTimeout(function () {
                new Glide("#glide1", {
                  type: "carousel",
                  perView: 3.2,
                  bound: true,
                  autoplay: 2000,
                  breakpoints: {
                    1100: {
                      perView: 2.2,
                    },
                    600: {
                      perView: 1.2,
                    },
                  },
                }).mount();
              }, 500);
            }

            this.artworksLoading = false;
          }, 500);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.glide__slides {
  padding: 30px 0;
}
</style>